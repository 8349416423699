<template>
  <div class="main_content program_emp_search employee_list align-items-center" style="min-height: 80vh">
    <div class="row">
      <!-- First row -->
      <div class="row align-items-center evaluator_profile bg_profile px-0 ps-3 ps-md-0" v-if="$route.name=='evaluator_employee_view_prev'">
        <div class="col-lg-12 col-12 ps-3 ps-md-5 pe-2 pe-md-4">
              <div class="main_work mb-3">
                <div class="work_area mb-0">
                  <h2 class="d-inline">Evaluator</h2>
                </div>
                <div class="program_list hover_links mb-lg-0 mb-3">
                  <ul class="list-unstyled d-flex flex-wrap mb-0">
                    <li>
                      <!-- <a href="">PBE Management Dashboard  </a> / -->
                      <router-link :to="{ name: 'evaluator' }">Evaluator </router-link> /
                      <router-link :to="{ name: 'evaluator_employee_search', params: { id: client_uid }  }"> Employee Search </router-link> /
                      <!-- <a href=""> {{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</a> -->
                      <span class="breadcrumb-item active"> {{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-9">
                  <div class="profile_img">
                    <img :src="base_url + '/avatars/' + employee_data.avatar" class="img-fluid" alt="">
                    <div class="profile-name">
                      <h3>{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }} <span> {{ employee_data.email }}</span></h3>
                      <div v-show="active_status" class="statusActive">
                        <h2> <span></span> Active </h2>
                      </div>
                      <div v-show="!active_status" class="statusActive no_active">
                        <h2> <span></span> Inactive </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-3 text-start text-md-end mt-4 mt-lg-3" v-show="startNewEvalPermission && formlink_ergo_eval">
                  <!-- <a href="https://survey.alchemer.com/s3/6916070/Test-Ergo-Eval" target="_blank" class="align-right btn btn_primary">New Evaluation</a> -->
                  <router-link :to="{ name: 'new_evaluation' ,params: { cid: client_uid, uid: this.employee_id}}" class="align-right btn btn_primary for_new_eval_btn" target="_blank">New Evaluation</router-link>
                </div>
              </div>
        </div>
       
      </div>
      <div class="row align-items-center bg_profile px-0 ps-5 ps-md-0" v-else>
        <div class="col-lg-8 col-xl-6 col-12 border_side_right">
          <div class="row ">
            <div class="col-sm-2 col-2 text-end px-0">
              <!-- <img :src="require('@/assets/images/dashboard/john_smith_profile.png')" class="img-fluid" width="68" alt=""> -->
              <img :src="base_url + '/avatars/' + employee_data.avatar" class="img-fluid" width="68" alt="">
            </div>
            <div class="col-sm-10 col-10">
              <div class="main_work">
                <div class="work_area mb-2">
                  <h2 class="d-inline">{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</h2>
                </div>
                <div class="program_list hover_links mb-lg-0 mb-3">
                  <ul class="list-unstyled d-flex flex-wrap mb-0" v-if="$route.name=='view_employee_prev'">
                    <!-- <li><a href="/user/employee-search">Program Manager Dashboard  </a></li> -->
                    <!-- <li>/<a href="/user/employee-search">  Employee Search </a></li> -->
                    <li> <router-link class="text-darks hov_router" :to="{name: 'employee_search'}"> Employee Search&nbsp;</router-link></li>
                    <li> / <span class="breadcrumb-item active">&nbsp;{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</span></li>
                    <!-- <li>/<a href="">&nbsp;{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</a></li> -->
                  </ul>
                  <ul class="list-unstyled d-flex flex-wrap mb-0" v-if="$route.name=='client_employee_view_prev'">
                    <!-- <li><a href="/user/client-manager">PBE Management Dashboard  </a></li> -->
                    <!-- <li> <a :href="'/user/client-employee-search/'+client_uid">Pbe Management&nbsp;</a></li> -->
                    <li> <router-link class="text-darks hov_router" :to="{name: 'client_manager'}">Program Management&nbsp;</router-link></li>
                    <li> / <a :href="'/user/client-employee-search/'+client_uid">&nbsp;Employee Search&nbsp;</a></li>
                    <!-- <li> / <a href="">&nbsp;{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</a></li> -->
                    <li> / <span class="breadcrumb-item active breadcrumb_font">&nbsp;{{ employee_data.first_name | capitalize }} {{ employee_data.last_name | capitalize }}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-6 col-12">
          <div class="record d-flex flex-column flex-sm-row flex-lg-wrap flex-nowrap justify-content-center justify-content-lg-start">
            <div class="ps-xl-5 ps-md-2 ps-0" v-if="showSelfAssessment">
              <p class="d-inline self_act b_bold">Self-Assessment Activity</p>
              <h3 class="d-inline ms-4" >({{ employee_data.pre_self_assessment_count }})</h3>
            </div>
            <div class="ps-0 ps-sm-5">
              <p class="d-inline b_bold">Ergo Eval Activity</p>
              <h3 class="d-inline ms-4">({{ employee_data.pre_ergo_eval_count }})</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- Table 1 -->
      <div v-if="showSelfAssessment" class="table_admin mt_50 px-4">
        <div class="col-md-12">
          <div class="table_card">
            <div class="row justify-content-end align-items-center pb_40">
              <div class="col-md-12 ">
                <div class="align-left ">
                  <h3 class="card_heading">Self-Assessment</h3>
                </div>
              </div>
            </div>
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
              <div class="dataTables_length" id="datatable_length">
                <label>Reports
                  <select name="datatable_length" aria-controls="datatable" class="" v-model.number="SAItemsPerPage"
                    @change="getSelfAssessmentsearch">
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>500</option>
                  </select>
                  entries</label>
              </div>
              <div id="datatable_filter" class="dataTables_filter mt-3 mt-lg-0">
                <label><input type="search" class="" aria-controls="datatable"
                    v-model="SAListConfig.search_string" v-on:keyup.enter="getSelfAssessmentsearch" placeholder="Search" />
                  <a v-if="SAListConfig.search_string" href="javascript:void(0)" @click="getSelfAssessmentsearch" class="search_icon slty_up">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </a>
                  <a href="javascript:void(0)" v-if="SAListConfig.search_string" @click="
                      SAListConfig.search_string = null;
                      getSelfAssessmentsearch();
                    " class="crossicon x_icon">
                    X
                  </a>
                </label>
              </div>
            </div>
            <div class="table-responsive table_audit_log eval_active_table">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr> 
                    <th class="sort eval_name_col">
                      <SortIcons
                        :lable="'First Name'"
                        :listConfig="SAListConfig"
                        :sortBy="'first_name'"
                        @sorting="SAsorting($event)"
                      />
                    </th>
                    <th class="sort eval_name_col">
                      <SortIcons
                        :lable="'Last Name'"
                        :listConfig="SAListConfig"
                        :sortBy="'last_name'"
                        @sorting="SAsorting($event)"
                      />
                    </th>
                    <th  style="text-align: start !important; width: 30%;">Email ADDRESS </th>
                    <th class="sort date_col_eval">
                      <SortIcons
                        :lable="'Date of Eval'"
                        :listConfig="SAListConfig"
                        :sortBy="'assessment_date'"
                        @sorting="SAsorting($event)"
                      />
                    </th>
                    <th  style="text-align: start !important; width: 10%;"  class="eval_col" >ACTION </th>
                  </tr>
                </thead>
                <tbody>
                  <tr  v-for="(report, indx) in SAreports.reports" :key="indx">
                    <td style="text-align:start; width: 15%;">
                      <p>{{ report.first_name }}</p>
                    </td>
                    <td style="text-align:start; width: 15%;">
                      <p>{{ report.last_name }}</p>
                    </td>
                    <td style="text-align:start; width: 30%;">
                      <p class="email_tag"><a :href="'mailto:' + report.user_email">{{ report.user_email }}</a></p>
                    </td>
                    <td style="text-align:start; width: 30%;">
                      <p>{{ report.formated_date ? report.formated_date : report.formated_assessment_date }}</p>
                    </td>
                    <td style="text-align:start ;width: 10%;">
                      <div class="edit_btn">
                        <p>
                          <router-link :to="{ name: employee_report_route, params: { url: report.u_id }, }" target="_blank" title="View Report" class="p-2">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')" alt="img" />
                          </router-link>
                          <a :href="'javascript:void(0)'" @click="downloadZipFile(report.summary_report, first_name,report.type_of_assessment)" title="Download">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/download.svg')" alt="img" />
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="sa_data_length==0">
                    <td colspan="5" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ sa_positionText }}</p>
            <VPagination v-if="sa_data_length" class="dataTables_paginate" :totalPages="sa_pages.length"
              :perPage="SAItemsPerPage" :currentPage.sync="SACurrentPage" @pagechanged="SAonPageChange" />
          </div>
        </div>
      </div>
      <!--Table 2 -->
      <div class="table_admin mt_50 px-4">
        <div class="col-md-12">
          <div class="table_card mb-5">
            <div class="row justify-content-end align-items-center pb_40">
              <div class="col-md-12">
                <div class="align-left ">
                  <h3 class="card_heading">Ergo Evals</h3>
                </div>
              </div>
            </div>
            <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                <div class="dataTables_length" id="datatable_length">
                  <label>Reports
                    <select name="datatable_length" aria-controls="datatable" class="" v-model.number="EEItemsPerPage"
                      @change="getErgoEvalsearch">
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                    </select>
                    entries</label>
                </div>
                <div id="datatable_filter" class="dataTables_filter mt-3 mt-lg-0">
                  <label><input type="search" class="" aria-controls="datatable" placeholder="Search"
                      v-model="EEListConfig.search_string" v-on:keyup.enter="getErgoEvalsearch" />
                    <a v-if="EEListConfig.search_string" href="javascript:void(0)" @click="getErgoEvalsearch" class="search_icon slty_up">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                        viewBox="0 0 16 16">
                        <path
                          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </a>
                    <a href="javascript:void(0)" v-if="EEListConfig.search_string" @click="
                        EEListConfig.search_string = null;
                        getErgoEvalsearch();
                      " class="crossicon x_icon">
                      X
                    </a>
                  </label>
                </div>
              </div>
            <div class="table-responsive table_audit_log eval_active_table">
              <table class="table table-bordered table dataTable no-footer">
                <thead>
                  <tr>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="eval_name_col">
                     <SortIcons
                        :lable="'First Name'"
                        :listConfig="EEListConfig"
                        :sortBy="'first_name'"
                        @sorting="EEsorting($event)"
                      />
                    </th>
                    <th style="text-align: start !important; width: 15%;" scope="col" class="eval_name_col">
                     <SortIcons
                        :lable="'Last Name'"
                        :listConfig="EEListConfig"
                        :sortBy="'last_name'"
                        @sorting="EEsorting($event)"
                      />
                    </th>
                    <!-- <th  style="text-align: start !important; width: 30%;" >Name</th> -->
                    <th  style="text-align: start !important; width: 20%;">Email ADDRESS </th>
                    <!-- <th  style="text-align: start !important; width: 20%;" scope="col">
                     <SortIcons
                        :lable="'Name'"
                        :listConfig="EEListConfig"
                        :sortBy="'user_name'"
                        @sorting="EEsorting($event)"
                      />
                    </th>
                    <th style="text-align: start !important; width: 25%;" scope="col">
                     <SortIcons
                        :lable="'Email Address'"
                        :listConfig="EEListConfig"
                        :sortBy="'user_email'"
                        @sorting="EEsorting($event)"
                      />
                    </th> -->
                    <th style="text-align: start !important; width: 20%;" scope="col" class="eval_date_col">
                      <SortIcons
                        :lable="'Date of Eval'"
                        :listConfig="EEListConfig"
                        :sortBy="'assessment_date'"
                        @sorting="EEsorting($event)"
                      />
                    </th>
                    <!-- <th style="text-align: start !important; width: 20%;" scope="col" class="eval_col" ><SortIcons
                        :lable="'Evaluator'"
                        :listConfig="EEListConfig"
                        :sortBy="'evaluator'"
                        @sorting="EEsorting($event)"
                      /></th> -->
                    <th  style="text-align: center !important; width: 10%;" scope="col" class="eval_col" >ACTION </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, indx) in EEreports.reports" :key="indx">
                    <td style="text-align: start !important; width: 10%;">
                      <p>{{ report.first_name }}</p>
                    </td>
                    <td style="text-align: start !important; width: 10%;">
                      <p>{{ report.last_name }}</p>
                    </td>
                    <td style="text-align: start !important; width: 25%;" >
                      <p class="email_tag"><a :href="'mailto:' + report.user_email">{{ report.user_email }}</a></p>
                    </td>
                    <td style="text-align: start !important; width: 25%;" >
                      <p>{{ report.formated_date ? report.formated_date : report.formated_assessment_date }}</p>
                    </td>
                    <td style="text-align: center !important; width: 10%;">
                      <div class="edit_btn">
                        <p>
                          <!-- <a href="#" @click="showReportDataSet(report.dataset)" class="view-dshboad-btn router-link-exact-active router-link-active p-2" title="Data Set"  data-bs-toggle="modal" data-bs-target="#dataset">
                            <img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')" alt="img" />
                          </a> -->
                          <router-link :to="{
                                  name: employee_report_route,
                                  params: { url: report.u_id },
                                }" target="_blank" title="Summary Report">
                                <img class="edit-icon" :src="require('@/assets/images/dashboard/file.png')"
                                  alt="img" />
                              </router-link>
                          <!-- <a href="#" aria-current="page" @click="getSingleReportHistory(report.id)" data-bs-toggle="modal" data-bs-target="#history"
                            class="router-link-exact-active router-link-active view-dshboad-btn p-2" title="History">
                            <img :src="require('@/assets/images/dashboard/timer.png')" alt="img" class="edit-icon">
                          </a> -->
                          <a :href="'javascript:'" @click="downloadZipFile(report.summary_report, report.first_name, report.type_of_assessment)" title="Download">
                            <img class="edit-icon" :src="require('@/assets/images/dashboard/download.svg')" alt="img" />
                          </a>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="ee_data_length==0">
                    <td colspan="5" class="text-center">No Records found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="float-lg-start text-sm-center">{{ ee_positionText }}</p>
            <VPagination v-if="ee_data_length" class="dataTables_paginate" :totalPages="ee_pages.length"
              :perPage="EEItemsPerPage" :currentPage.sync="EECurrentPage" @pagechanged="EEonPageChange" />
          </div>
        </div>
      </div>
    </div>
    <HistoryModal v-bind:history="allCommentArr" v-bind:isShowHistory="showHistory" ref="historyModal"></HistoryModal>
    <DatasetModal v-bind:dataSet="dataSet" v-bind:isEmptyDataSet="isEmptyDataSet"></DatasetModal>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import AuthService from "@/services/AuthService";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import DatasetModal from "@/components/DatasetModal";
import HistoryModal from "@/components/HistoryModal";
import commonFunction  from '../../mixin/commonFunction'

export default {
  name: "view_employee",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    DatasetModal,
    HistoryModal
  },
  data() {
    return {
      employee_report_route:'',
      commonLoader:false,
      active_status: 1,
      commentArr: [],
      dataSet: [],
      isEmptyDataSet: true,
      addCommentArr: {
        report_id: null,
        comments: null,
      },
      allCommentArr: [],
      base_url: '',
      client_uid: '',
      show: false,
      startNewEvalPermission : false,
      reportAvailable: true,
      showHistory: false,
      first_name: "", 
      isDisabled: true,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      employee_data: [],
      reportData: [],
      //SA Table Data
      SAreports: [],
      SACurrentPage: 1,
      SAItemsPerPage: 10,
      SAListConfig: {
        offset: (this.SACurrentPage - 1) * this.SAItemsPerPage,
        limit: this.SAItemsPerPage,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
        employee_id: null,
      },
      sa_data_length: 0,

      //EE Table Data
      EEreports: [],
      EECurrentPage: 1,
      EEItemsPerPage: 10,
      EEListConfig: {
        offset: (this.EECurrentPage - 1) * this.EEItemsPerPage,
        limit: this.EEItemsPerPage,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
        employee_id: null,
      },
      ee_data_length: 0,
      formlink_ergo_eval: null,
      showSelfAssessment: true,
    };
  },
  computed: {
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    sa_pages() {
      if (this.sa_data_length <= this.SAItemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.sa_data_length / this.SAItemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    sa_positionText: function () {
      var endIndex = this.SACurrentPage * this.SAItemsPerPage,
        startIndex = (this.SACurrentPage - 1) * this.SAItemsPerPage + 1;
      if (this.sa_data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.sa_data_length ? this.sa_data_length : endIndex) +
        " of " +
        this.sa_data_length
      );
    },
    ee_pages() {
      if (this.ee_data_length <= this.EEItemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.ee_data_length / this.EEItemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    ee_positionText: function () {
      var endIndex = this.EECurrentPage * this.EEItemsPerPage,
        startIndex = (this.EECurrentPage - 1) * this.EEItemsPerPage + 1;
      if (this.ee_data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.ee_data_length ? this.ee_data_length : endIndex) +
        " of " +
        this.ee_data_length
      );
    },
  },
  destroyed() {
    this.$parent.employeeDashboard = false;
  },
  beforeMount() {
    if (this.$route.params.id != undefined) {
      this.employee_id = this.$route.params.id;
      this.buttonUpdate = true;
      this.getEmployeeDetail(this.$route.params.id);
    }
    if (localStorage.getItem("userToken")) {
      window.addEventListener("scroll", this.scrollvue);
      // Store for check permission
      let res = this.$store.dispatch("auth/getAuthUser");
      res
        .then((value) => {
          let allPermission = value.user.roles.all_permission;
          if (allPermission.includes("evaluator-start-new-evaluation")) {
            this.startNewEvalPermission = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.tokenExpired();
    }
  },
  watch: {
    SACurrentPage(newValue) {
      this.SAListConfig.offset = (newValue - 1) * this.SAItemsPerPage;
      this.getSelfAssessmentReports();
    },
    EECurrentPage(newValue) {
      this.EEListConfig.offset = (newValue - 1) * this.EEItemsPerPage;
      this.getErgoEvalReports();
    },
  },
  mounted() {
    var clientData = this.$store.getters["auth/authUser"];
    if(this.$router.currentRoute.name == 'client_employee_view_prev'){
      this.$parent.clientManagerDashboard = true;
      this.employee_report_route = 'client_employee_reports_prev';
    }
    else if(this.$router.currentRoute.name == 'view_employee_prev'){
      this.$parent.employeeDashboard = true;
      this.employee_report_route = 'employee_reports_prev';
      if(clientData.client.client_type != 1){
        this.showSelfAssessment = false;
      }
    } else{
      this.$parent.employeeDashboard = true;
      this.employee_report_route = 'evaluator_employee_reports_prev';
    }
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    this.base_url = process.env.VUE_APP_API_URL;
    this.SAListConfig = {
      offset: (this.SACurrentPage - 1) * this.SAItemsPerPage,
      limit: this.SAItemsPerPage,
      sortBy: "assessment_date",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
      employee_id: this.employee_id,
    };
    this.EEListConfig = {
      offset: (this.EECurrentPage - 1) * this.EEItemsPerPage,
      limit: this.EEItemsPerPage,
      sortBy: "assessment_date",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
      employee_id: this.employee_id,
    };
    this.$parent.employeeDashboard = true;
    this.getSelfAssessmentReports();
    this.getErgoEvalReports();
  },
  methods: {
    getSingleReportHistory(reportid){
      if (reportid) {
        this.showHistory = false;
        this.commentArr = [];
        this.allCommentArr = [];
        this.addCommentArr.report_id = reportid;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/reports-history",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: this.commentArr,
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.allCommentArr = data.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              this.allCommentArr = [];
              if (response.status == 401) {
                _this.$router.push({ name: "login" });
              }
            });
        } else {
          this.tokenExpired();
        }
      }
    },    
    showReportDataSet(dataSet){
      if(dataSet != null){
        var i = 1;
        for (const item in dataSet) {
          dataSet[item].id = i++;
          if(dataSet[item].options){
            for (const item1 in dataSet[item].options) {
              if(dataSet[item].options[item1].answer){
                if(dataSet[item].answer)
                  dataSet[item].answer += ', '+dataSet[item].options[item1].answer;
                else
                  dataSet[item].answer = dataSet[item].options[item1].answer;
              }
            }
          }
          if(dataSet[item].subquestions){
            for (const item2 in dataSet[item].subquestions) {
              if(dataSet[item].subquestions[item2].answer){
                if(dataSet[item].answer){
                  dataSet[item].answer += ', '+dataSet[item].subquestions[item2].question+' : '+dataSet[item].subquestions[item2].answer;
                }
                else{
                  dataSet[item].answer = dataSet[item].subquestions[item2].question+' : '+dataSet[item].subquestions[item2].answer;
                }
              }
            }
          }
        }
        this.dataSet = dataSet;
        this.isEmptyDataSet = Object.keys(dataSet).length === 0;
      } else {
        this.dataSet = [];
        this.isEmptyDataSet= true;
      }
    },
    downloadZipFile(url, username, type_of_assessment) {
      var fileName ="report";
      if(type_of_assessment == "Self-Assessment"){
            fileName = username+"-self-assessment.html";
      }else if(type_of_assessment == "Ergo Eval"){
            fileName = username+"-ergo-eval.html";
      }
      AuthService.downloadZipFile(url, username).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    SAonPageChange(page) {
      this.SACurrentPage = page;
    },
    EEonPageChange(page){
      this.EECurrentPage = page;
    },
    SAsorting($event) {
      this.SAListConfig.sortBy == $event.sortBy;
      this.SAListConfig.order == $event.order;
      this.getSelfAssessmentReports();
    },
    EEsorting($event) {
      this.EEListConfig.sortBy == $event.sortBy;
      this.EEListConfig.order == $event.order;
      this.getErgoEvalReports();
    },
    getSelfAssessmentsearch() {
      this.SACurrentPage = 1;
      this.SAListConfig.limit = this.SAItemsPerPage;
      this.getSelfAssessmentReports();
    },
    getErgoEvalsearch() {
      this.EECurrentPage = 1;
      this.EEListConfig.limit = this.EEItemsPerPage;
      this.getErgoEvalReports();
    },
    getEmployeeDetail(id) {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/evaluator-view-employee/" + id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if(data.success)
          {
            var decodedData = _this.decodeAPIResponse(data.data);
            _this.employee_data = decodedData.records;
            if (decodedData.records.active_status === 0) {
              _this.active_status = false;
            }
            //  else {
            //   _this.employee_data.active_status = false;
            // }
            if( decodedData.records.temporary_access != ''  && decodedData.records.temporary_access != null &&  decodedData.records.temporary_access != '0000-00-00' ){
              _this.employee_data.temporary_access =new Date(decodedData.records.temporary_access);
            }
            _this.client_uid = decodedData.client_uid;
            _this.formlink_ergo_eval = decodedData.formlink_ergo_eval;
            _this.employee_data.client_ids = JSON.parse(decodedData.records.client_ids);
          } else {
            _this.$toast.error(data.message, {
              position: "top-right",
            });
            _this.$router.push({ name: "all_user" });
          }
        })
        .catch(({ response }) => {
          
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
          if (response.status == 403) {
            _this.$router.push({ name: "403" });
          }
        });
    },
    getErgoEvalReports(){
      var _this = this;
      if (localStorage.getItem("userToken")) {
        if(_this.commonLoader){
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        }
        _this.isLoader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/qual-ergo-eval-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.EEListConfig)},
        };
        _this
        .axios(config)
        .then(({data}) => {
          var decodedData = _this.decodeAPIResponse(data.data);
          _this.first_name = decodedData.first_name;
          _this.EEreports = decodedData;
          _this.ee_data_length = decodedData.counts;
          if(_this.commonLoader){
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }
        })
        .catch(({response}) => {
          if(_this.commonLoader){
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }
          if (response.status == 401) {
            _this.$router.push({ name: "login" });
          }
        });
      } else {
        _this.tokenExpired();
      }
    },
    getSelfAssessmentReports() {
      var _this = this;
      if (localStorage.getItem("userToken")) {
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        _this.isLoader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/qual-self-assessment-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.SAListConfig)},
        };
        _this
        .axios(config)
        .then(({data}) => {
          var decodedData = _this.decodeAPIResponse(data.data);
          _this.first_name = decodedData.first_name;
          _this.SAreports = decodedData;
          _this.sa_data_length = decodedData.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.commonLoader = true;
        })
        .catch(({response}) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.commonLoader = true;
          // _this.$toast.error(response.data.error, {
          //   position: "top-right",
          //   duration: 5000,
          // });
          if (response.status == 401) {
            _this.$router.push({ name: "login" });
          }
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
        });
      } else {
        _this.tokenExpired();
      }
    },
  },
};
</script>